import React from "react";
import { Link } from "react-router-dom";
import Header2 from "../layout/header2";
import Sidebar from "../layout/sidebar";

function ActionConfirmation(props) {
  const mainMessage = props.mainMessage;
  const sideMessage = props.sideMessage;
  const redirectionPath = props.redirectPath;
  let iconClass = !props.isError ? "icon-check" : "icon-error";
  let icon = !props.isError ? "fa fa-check" : "fa fa-times";

  return (
    <>
      <div className="verification section-padding">
        <div className="container h-100">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-xl-5 col-md-6">
              <div className="auth-form card">
                <div className="card-body">
                  <form action="#" className="identity-upload">
                    <div className="identity-content">
                      <span className={iconClass}>
                        <i className={icon}></i>
                      </span>
                      <h4>{mainMessage}</h4>
                      <p>{sideMessage}</p>
                    </div>

                    <div className="text-center">
                      <Link
                        to={redirectionPath}
                        type="submit"
                        className="btn btn-success ps-5 pe-5"
                      >
                        Continuar
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ActionConfirmation;
