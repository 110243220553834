import React, { Component } from "react";

export class BalanceCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
    };
  }

  fetchUser = async () => {
    const hostname = process.env.REACT_APP_API_BASE_URL
      ? process.env.REACT_APP_API_BASE_URL
      : "http://localhost:8080";
    const user = await fetch(hostname.concat("/user/id/" + 1))
      .then((response) => {
        return response.json();
      })
      .catch(() => {
        var user = {
          balance: 0,
        };
        return user;
      });
    this.setState({ user: user });
  };

  componentDidMount() {
    this.fetchUser();
  }

  render() {
    return (
      <div class="col-xl-12">
        <div class="row">
          <div class="col-xl-3 col-lg-6 col-md-6">
            <div class="widget-card">
              <div class="widget-title">
                <h5>Balance</h5>
              </div>
              <div class="widget-info">
                <h3>{this.state.user.balance}</h3>
                <p>USD</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BalanceCard;
