import React from "react";
import { Link } from "react-router-dom";
import BalanceCard from "./balance-card";

class TransferView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      receiverDni: "",
      amount: 0,
      isValidReceiver: true,
      receiver: {},
      errors: {},
    };

    this.handleDniChange = this.handleDniChange.bind(this);
    this.handleAmountChange = this.handleAmountChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateReceiver = this.validateReceiver.bind(this);
  }

  componentDidMount() {
    this.fetchUser();
  }

  fetchUser = async () => {
    const hostname = process.env.REACT_APP_API_BASE_URL
      ? process.env.REACT_APP_API_BASE_URL
      : "http://localhost:8080";
    let response = await fetch(hostname.concat("/user/id/" + 1));
    let user = await response.json();
    this.currentBalance = user.balance;
  };

  handleDniChange(event) {
    this.setState({ receiverDni: event.target.value });
  }

  handleAmountChange(event) {
    this.setState({ amount: event.target.value });
  }

  validateReceiver(event) {
    let dniInput = this.state.receiverDni;
    if (!(dniInput.length > 0 && dniInput.length <= 8)) {
      let errors = this.state.errors;
      errors["dni"] = "La cedula debe ser de largo entre 0 y 8";
      this.setState({
        errors: errors,
      });
      console.log(this.state);
      return;
    }
    this.setState({
      errors: {},
    });
    let receiver = this.fetchReceiver(this.state.receiverDni);
    receiver.then((value) => this.setState({ receiver: value }));
    this.setState({ isValidReceiver: false });
  }

  fetchReceiver = async (dni) => {
    const response = await fetch("http://localhost:8080/user/dni/" + dni);
    const receiver = await response.json();
    return receiver;
  };

  async handleSubmit(event) {
    event.preventDefault();
    let errors = this.state.errors;
    if (this.state.amount <= 0) {
      errors["amount"] = "El monto no puede ser menor o igual a 0";
      this.setState({
        errors: errors,
      });
      return;
    } else if (this.currentBalance < this.state.amount) {
      errors["amount"] =
        "El valor del monto no puede ser mayor al balance de tu cuenta";
      this.setState({
        errors: errors,
      });
      return;
    }

    this.setState({
      errors: {},
    });
    const response = await fetch(
      "http://localhost:8080/transfer?" +
        new URLSearchParams({
          senderDni: "24658123",
          receiverDni: this.state.receiverDni,
          amount: this.state.amount,
        }),
      { method: "POST" }
    );
    const status = await response.status;
    if (status == 200) this.props.history.push("/transfer-confirmed");
    else this.props.history.push("/transfer-error");
  }

  render() {
    return (
      <>
        <div className="content-body">
          <div className="container">
            <div className="row">
              <BalanceCard />
              <div className="col-xl-5 col-lg-5 col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="buy-sell-widget">
                      <form
                        name="myform"
                        className="currency_validate"
                        onSubmit={this.handleSubmit}
                      >
                        <div className="mb-3">
                          <label className="me-sm-2">
                            Cedula de Identidad del Destinatario
                          </label>
                          <div className="input-group mb-3">
                            <input
                              type="text"
                              name="receiverDni"
                              className="form-control"
                              value={this.state.receiverDni}
                              onChange={this.handleDniChange}
                            />
                          </div>
                        </div>
                        <span style={{ color: "red" }}>
                          {this.state.errors["dni"]}
                        </span>
                        <div className="mb-3">
                          <label className="me-sm-2">Monto a Transferir</label>
                          <div className="input-group mb-3">
                            <input
                              type="text"
                              name="usd_amount"
                              className="form-control"
                              value={this.state.amount}
                              onChange={this.handleAmountChange}
                              disabled={this.state.isValidReceiver}
                            />
                          </div>
                          <span style={{ color: "red" }}>
                            {this.state.errors["amount"]}
                          </span>
                        </div>
                        <button
                          type="button"
                          name="validate"
                          onClick={this.validateReceiver}
                          className="btn btn-success w-100 mb-3"
                        >
                          Validar
                        </button>
                        <button
                          disabled={this.state.isValidReceiver}
                          type="submit"
                          name="submit"
                          className="btn btn-success w-100"
                        >
                          Trasferir
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {!this.state.isValidReceiver && (
                <div className="col-xl-7 col-lg-7 col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="buyer-seller">
                        <div className="d-flex justify-content-between mb-3">
                          <div className="seller-info text-end">
                            <div className="d-flex align-items-start">
                              <div className="flex-grow-1">
                                <h3>{this.state.receiver.name}</h3>
                                <Link to={"#"}>
                                  {this.state.receiver.email}
                                </Link>
                              </div>
                              <img
                                className="ms-3"
                                src={require("./../../images/profile/1.png")}
                                alt=""
                                width="50"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/*TODO add explanation to use this form*/}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default TransferView;
