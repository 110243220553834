import React from "react";
import { useState, useEffect } from "react";
import BalanceCard from "./balance-card";

function Dashboard() {
  const [transactions, setTransanctions] = useState([]);
  const currentUserId = 1;
  useEffect(() => {
    fetchUserTransactions();
  }, []);

  const fetchUserTransactions = async () => {
    const hostname = process.env.REACT_APP_API_BASE_URL
      ? process.env.REACT_APP_API_BASE_URL
      : "http://localhost:8080";
    const transactions = await fetch(
      hostname.concat("/transactions/" + currentUserId)
    )
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        } else {
          return new Array();
        }
      })
      .catch(() => {
        return new Array();
      });
    setTransanctions(transactions);
  };

  let trxItemsList = transactions.map((trx) => {
    let trxSymbol = "buy-thumb";
    if (currentUserId === trx.senderId) {
      trxSymbol = "sold-thumb";
    }
    return (
      <li class="d-flex">
        <span class={trxSymbol}>
          <i class="la la-arrow-down"></i>
        </span>
        <div class="flex-grow-1">
          <p>
            <small>{trx.date}</small>
          </p>
        </div>
        <div class="text-right">
          <h4>{trx.amount}</h4>
        </div>
      </li>
    );
  });

  return (
    <>
      <div className="content-body">
        <div className="container">
          <div class="row">
            <BalanceCard />
            <div class="col-xl-12">
              <div class="row">
                <div class="col-xl-6 col-lg-12 col-xxl-8">
                  <div class="card">
                    <div class="card-header">
                      <h4 class="card-title">Transaction </h4>
                    </div>
                    <div class="card-body">
                      <div class="transaction-widget">
                        <ul class="list-unstyled">
                          {trxItemsList.length ? (
                            trxItemsList
                          ) : (
                            <li>
                              <div>No existen transacciones aún</div>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
