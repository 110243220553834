import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Accounts from "./pages/accounts";
import AddBankAccount from "./pages/add-bank-acc";
import AddDebitCard from "./pages/add-debit-card";
import TransferView from "./pages/transfers";
import History from "./pages/history";
import Dashboard from "./pages/dashboard";
import Landing from "./pages/landing";
import Locked from "./pages/lock";
import Otp1 from "./pages/otp-1";
import Otp2 from "./pages/otp-2";
import PrivacyPolicy from "./pages/privacy-policy";
import Reset from "./pages/reset";
import Settings from "./pages/settings";
import SettingsAccount from "./pages/settings-account";
import Preferences from "./pages/settings-preferences";
import SettingsSecurity from "./pages/settings-security";
import Signin from "./pages/signin";
import Signup from "./pages/signup";
import TermsCondition from "./pages/terms-condition";
import ActionConfirmation from "./pages/action-confirmation";
import PageTitle from "./element/page-title";
import Header2 from "./layout/header2";
import Sidebar from "./layout/sidebar";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      isApiDown: false,
    };
  }

  componentDidMount() {
    this.fetchUser();
  }

  fetchUser = async () => {
    var isApiDown = false;
    const hostname = process.env.REACT_APP_API_BASE_URL
      ? process.env.REACT_APP_API_BASE_URL
      : "http://localhost:8080";
    const response = await fetch(hostname.concat("/user/id/" + 1))
      .then((response) => {
        return response.json();
      })
      .catch((err) => {
        isApiDown = true;
        const response = {
          name: "",
        };
        return response;
      });

    this.setState({ user: response, isApiDown: isApiDown });
  };

  render() {
    return (
      <>
        <BrowserRouter basename="/">
          <div id="main-wrapper">
            <Header2 userName={this.state.user.name} />
            <Sidebar />
            <PageTitle userName={this.state.user.name} />
            <Switch>
              <Route exact path="/">
                {this.state.isApiDown ? (
                  <ActionConfirmation
                    mainMessage="Error backend"
                    sideMessage="Servicio temporalmente abajo, intente mas tarde"
                    isError={true}
                    redirectPath="/landing"
                  />
                ) : (
                  <Dashboard />
                )}
              </Route>
              <Route
                path="/transfer"
                render={(props) => <TransferView {...props} />}
              />
              <Route path="/accounts" component={Accounts} />
              <Route path="/settings" component={Settings} />
              <Route path="/settings-preferences" component={Preferences} />
              <Route path="/settings-security" component={SettingsSecurity} />
              <Route path="/settings-account" component={SettingsAccount} />
              <Route path="/add-bank-acc" component={AddBankAccount} />
              <Route path="/add-debit-card" component={AddDebitCard} />
              <Route path="/lock" component={Locked} />
              <Route path="/otp-1" component={Otp1} />
              <Route path="/otp-2" component={Otp2} />
              <Route path="/privacy-policy" component={PrivacyPolicy} />
              <Route path="/reset" component={Reset} />
              <Route path="/signin" component={Signin} />
              <Route path="/signup" component={Signup} />
              <Route path="/terms-condition" component={TermsCondition} />
              <Route path="/transfer-confirmed">
                <ActionConfirmation
                  mainMessage="Transferencia exitosa"
                  sideMessage="La transferencia se ha realizado correctamente"
                  isError={false}
                  redirectPath="/transfer"
                />
              </Route>
              <Route path="/transfer-error">
                <ActionConfirmation
                  mainMessage="Error en Transferencia"
                  sideMessage="La transferencia no se ha podido completar"
                  isError={true}
                  redirectPath="/transfer"
                />
              </Route>
              <Route path="/history" component={History} />
              <Route path="/landing" component={Landing} />
            </Switch>
          </div>
        </BrowserRouter>
      </>
    );
  }
}

export default Index;
